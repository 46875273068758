<template>
  <div>
    <div class="mt-3">
      <form
        ref="form"
        @submit.stop.prevent="handleEdit"
      >
        <b-form-group
          label="Name"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-input
            id="name-input"
            v-model="years.year"
            required
          />
        </b-form-group>
        <div>
          <b-form-datepicker
            v-model="years.register_date"
            locale="en"
          />
        </div>
      </form>
      <div class="text-right mt-3">
        <b-button
          variant="danger"
          class="mr-2"
          @click="closeEditModal"
        >
          {{ $t('Cancel') }}
        </b-button>
        <b-button
          variant="success"
          @click="handleEdit"
        >
          {{ $t('Edit') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { editYear } from '@/api/directories'

export default {
  name: 'EditYears',
  props: {
    // eslint-disable-next-line vue/require-prop-types
    years: {
      default: null,
    },
  },

  data() {
    return {
      // eslint-disable-next-line vue/no-dupe-keys
      years: {
        year: '',
        register_date: null,
      },
    }
  },
  watch: {
    years: {
      immediate: true,
      handler(val) {
        this.years = val
      },
    },
  },
  methods: {
    handleEdit() {
      if (this.years.year && this.years.register_date) {
        // eslint-disable-next-line no-unused-vars
        editYear(this.years).then(res => {
          this.$emit('editYear')
        })
      }
    },
    closeEditModal() {
      this.$emit('closeEditModal')
    },
  },
}
</script>

<style scoped>

</style>
