<template>
  <div>
    <b-button
      class="bg-success"
      @click="modalActive=true"
    >
      {{ $t('Add') }}
    </b-button>
    <div class="mt-3">
      <b-modal
        id="modal-prevent-closing"
        ref="modal"
        v-model="modalActive"
        :title="$t('Add_Year')"
        hide-footer
      >
        <form
          ref="form"
          @submit.stop.prevent="handleSubmit"
        >
          <b-form-group
            :label="$t('title')"
            label-for="name-input"
            invalid-feedback="Name is required"
          >
            <b-form-input
              id="name-input"
              v-model="year.year"
              required
            />
          </b-form-group>
        </form>
        <div>
          <b-form-datepicker
            v-model="year.register_date"
            locale="en"
          />
        </div>
        <div class="text-right mt-3">
          <b-button
            variant="danger"
            class="mr-2"
            @click="modalActive = false"
          >
            {{ $t('Cancel') }}
          </b-button>
          <b-button
            variant="success"
            @click="handleSubmit"
          >
            {{ $t('Add_Year') }}
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { addYear } from '@/api/directories'

export default {
  name: 'YearsModal',
  data() {
    return {
      modalActive: false,
      year: {
        year: '',
        register_date: null,
      },
    }
  },
  methods: {
    handleSubmit() {
      if (this.year.year && this.year.register_date) {
        // eslint-disable-next-line no-unused-vars
        addYear(this.year).then(res => {
          this.modalActive = false
          this.$emit('yearAdded')
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
