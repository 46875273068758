<template>
  <div>
    <b-row>
      <b-col md="9">
        <YearsModal @yearAdded="yearAdded" />
      </b-col>
      <div class="w-100">
        <b-card>
          <b-table
              striped
              hover
              :items="years"
              :fields="fields"
              responsive
          >
            <template #cell(id)="data">
              <span class="text-md text-primary"> {{ (data.index +1) }}</span>
            </template>
            <template #cell(register_date)="{item}">
              {{item.register_date | dateFormat}}
            </template>
            <template #cell(action)="data">
              <b-button
                  size="sm"
                  variant="warning"
                  @click="handleEdit(data.item)"
              >
                <b-icon icon="pencil" />
              </b-button>
              <b-button
                  class="ml-1"
                  size="sm"
                  variant="danger"
                  @click="handleDelete(data.item.id)"
              >
                <b-icon icon="trash" />
              </b-button>
            </template>
          </b-table>
        </b-card>
        <b-modal
          id="modal-prevent-closing"
          ref="modal"
          v-model="modalActive"
          title="Add Year"
          hide-footer
        >
          <EditYears
            :years="activeYears"
            @closeEditModal="closeModal"
            @editYear="yearEdited"
          />
        </b-modal>
      </div>
    </b-row>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions

// eslint-disable-next-line import/extensions
import YearsModal from '@/views/loginpages/moderator/components/year/YearsModal'
import { deleteYear, getYears } from '@/api/directories'
// eslint-disable-next-line import/extensions
import EditYears from '@/views/loginpages/moderator/components/year/EditYears'

export default {
  name: 'Years',
  components: { EditYears, YearsModal },
  data() {
    return {
      years: [],
      modalActive: false,
      activeYears: null,
      fields: [
        {
          key: 'id',
          label: this.$t('ID'),
        },
        {
          key: 'year',
          label: this.$t('Year'),
        },
        {
          key: 'register_date',
          label: this.$t('Date'),
        },
        {
          key: 'action',
          label: this.$t('Action'),
        },
      ],
    }
  },
  created() {
    this.fetchYears()
  },
  methods: {
    fetchYears() {
      getYears().then(response => {
        this.years = response.data.data
      })
    },
    handleEdit(data) {
      // eslint-disable-next-line no-undef
      this.activeYears = _.cloneDeep(data)
      this.modalActive = true
    },
    yearAdded() {
      this.fetchYears()
    },
    closeModal() {
      this.modalActive = false
    },
    yearEdited() {
      this.modalActive = false
      this.fetchYears()
    },
    handleDelete(id) {
      if (id) {
        this.$swal({
          title: this.$t('areYouSure'),
          text: this.$t('wontReturn'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('YesDelete'),
          cancelButtonText: this.$t('Cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
        }).then(result => {
          if (result.value) {
            // eslint-disable-next-line no-unused-vars
            deleteYear({ id }).then(res => {
              this.$swal({
                icon: 'success',
                title: this.$t('Deleted'),
                text: this.$t('dataHasDeleted'),
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.fetchYears()
            })
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: this.$t('Cancelled'),
              text: this.$t('yrDataSafe'),
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
